const getLanguageAndEvaluationsMap = (language, languages, rddEvaluations) => {
  let rddEvaluationsMap;


  const languageObject = Object.values(languages).find((lang) => lang.code === language?.toUpperCase());
  const languageId = languageObject ? Object.keys(languages).find((key) => languages[key].code === language.toUpperCase()) : null;

  if (languageId) {
    rddEvaluationsMap = Object.fromEntries(
      rddEvaluations.map((rddEval, i) => [
        i,
        { name: rddEval.languages.find((lang) => lang.id == languageId)?.name || '', value: rddEval.name },
      ])
    );
  } else {
    rddEvaluationsMap = Object.fromEntries(
      rddEvaluations.map((rddEval, i) => [
        i,
        { name: rddEval.languages[0]?.name || '', value: rddEval.name },
      ])
    );
  }

  return rddEvaluationsMap;
};

export default getLanguageAndEvaluationsMap;