import React, { useEffect, useState, useMemo, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useStoryblok } from "../../../services/storyblokService";
import { ApiService } from "../../../services/apiService";
import { getFieldList } from "../../../store/app";
import Overlay from "../../../components/Overlay";
import styles from "../../../styles/LandingPage.module.css";
import ArrowRight from "../../../components/Slider/ArrowRight";
import ArrowLeft from "../../../components/Slider/ArrowLeft";

// Lazy-loaded components
const SliderItem = lazy(() => import("../../../components/Slider/SliderItem"));
const SliderItemRecommendations = lazy(() => import("../../../components/Slider/SliderItemRecommendations"));
const RecipeItem = lazy(() => import("../../../components/RecipeItem"));
const ThreeColumns = lazy(() => import("../../../components/ThreeColumns"));
const CarouselItem4 = lazy(() => import("../../../components/CarouselItem4"));

const LandingPage = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.app);
  const redux_languages = useSelector((state) => state.storyBlok.languages);

  const [recommendations, setRecommendations] = useState([]);
  const [windowHeight, setWindowHeight] = useState(600);

  const [isLoading, setIsLoading] = useState(true);



  // Memoized Storyblok hooks
  const SB_banners = useStoryblok(null, {
    starts_with: "banners_folder",
    filter_query: { Featured: { is: true } },
    sort_by: "content.Order:asc:int",
  });
  const SB_recommendationSection = useStoryblok("recommendation-section");
  const SB_articlesSection = useStoryblok("articles-section");
  const SB_articles = useStoryblok(null, {
    starts_with: "articles_folder",
    filter_query: { Featured: { is: true } },
  });
  const SB_reviewSection = useStoryblok("review-section");
  const SB_reviews = useStoryblok(null, { starts_with: "reviews" });
  const SB_webMenu = useStoryblok("web-menu");
  const SB_recipeMultilingueFields = useStoryblok("recipe-multilingue-fields");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const recom = await ApiService.getRecommendations();
        setRecommendations(Array.isArray(recom) ? recom : []);
        dispatch(getFieldList());
      } catch (error) {
        console.error("Error fetching data:", error);
        setRecommendations([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      const height = Math.min(Math.max(window.innerWidth / 2, 200), 600);
      setWindowHeight(height);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(getFieldList());
  }, [language, dispatch]);

  const renderBannerItems = useMemo(() => {
    return SB_banners?.stories?.map((element) => (
      <Suspense key={element.content._uid} fallback={<div>Loading...</div>}>
        <SliderItem
          title={element.content?.Title}
          body={element.content?.Body}
          imgSrc={element.content?.Image?.filename}
          tryItNow={SB_webMenu?.content?.Try_it_now}
        />
      </Suspense>
    ));
  }, [SB_banners, SB_webMenu]);

  const renderRecommendationsItems = useMemo(() => {
    return recommendations?.map((ele, index) => (
      <Suspense key={index} fallback={<div>Loading...</div>}>
        <SliderItemRecommendations>
          <RecipeItem
            recipe={ele}
            SB_recipeMultilingueFields={SB_recipeMultilingueFields}
          />
        </SliderItemRecommendations>
      </Suspense>
    ));
  }, [recommendations, SB_recipeMultilingueFields]);

  if (isLoading || !(SB_banners && redux_languages)) {
    return <Overlay />;
  }

  return (
    <div className="d-flex flex-column align-items-center">
      {/* Banners Slider */}
      <div className="slider-landingPage">
        <Slider
          slidesToShow={1}
          centerMode={false}
          fade
          infinite={true}
          nextArrow={<ArrowRight space={20} />}
          prevArrow={<ArrowLeft space={20} />}
        >
          {renderBannerItems}
        </Slider>
      </div>

      {/* Recommendations Section */}
      <SectionTitle title={SB_recommendationSection?.content?.Title} />
      <SectionBody body={SB_recommendationSection?.content?.Body} />
      <RecommendationsSlider
        recommendations={recommendations}
        windowHeight={windowHeight}
        renderItems={renderRecommendationsItems}
      />

      {/* Articles Section */}
      <SectionTitle title={SB_articlesSection?.content?.Title} />
      <SectionBody body={SB_articlesSection?.content?.Body} />
      <ArticlesSlider
        articles={SB_articles?.stories}
        articleSection={SB_articlesSection?.content}
      />

      {/* Reviews Section */}
      <ReviewsSection
        reviewSection={SB_reviewSection?.content}
        reviews={SB_reviews?.stories}
      />
    </div>
  );
};

// Helper components
const SectionTitle = React.memo(({ title }) => (
  <span className={`orangeLineUnderText ${styles.hungryForInsights_title}`}>
    {title}
  </span>
));

const SectionBody = React.memo(({ body }) => (
  <p style={{
    width: "90%",
    marginTop: 20,
    fontSize: 16,
    fontWeight: 400,
    color: "#6B6C72",
    marginBottom: "70px",
    textAlign: "center",
  }}>
    {body}
  </p>
));

const RecommendationsSlider = React.memo(({ recommendations, windowHeight, renderItems }) => (
  <div style={{
    width: "90%",
    fontFamily: "Roboto, sans serif",
    marginBottom: "40px",
    height: "500px", /* Inserted this because recommenadtions sections is too long*/
  }}>
    {recommendations.length > 0 && (
      <Slider
        style={{ height: windowHeight }}
        className="center"
        centerPadding="2%"
        centerMode
        slidesToShow={3}
        infinite
        nextArrow={<ArrowRight space={20} />}
        prevArrow={<ArrowLeft space={20} />}
      >
        {renderItems}
      </Slider>
    )}
  </div>
));

const ArticlesSlider = React.memo(({ articles, articleSection }) => (
  <Slider
    style={{ width: "80%", height: "550px" }}
    slidesToShow={3}
    infinite={true}
    slidesToScroll={3}
    nextArrow={<ArrowRight space={-40} />}
    prevArrow={<ArrowLeft space={-40} />}
    responsive={[
      {
        breakpoint: 900,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ]}
  >
    {articles?.map((element) => (
      <Suspense key={element.content._uid} fallback={<div>Loading...</div>}>
        <ThreeColumns
          story={element}
          articleSection={articleSection}
        />
      </Suspense>
    ))}
  </Slider>
));

const ReviewsSection = React.memo(({ reviewSection, reviews }) => (
  <>
    <div className="d-flex justify-content-center" style={{
      width: 60,
      paddingBottom: 15,
      borderBottom: "2px solid #FC9C52",
    }}>
      <span style={{
        whiteSpace: "nowrap",
        fontSize: 38,
        fontWeight: "bold",
        color: "#07122E",
        textAlign: "center",
      }}>
        {reviewSection?.Title}
      </span>
    </div>
    <p style={{
      maxWidth: 445,
      marginTop: 20,
      fontSize: 16,
      fontWeight: 400,
      color: "#6B6C72",
    }}>
      {reviewSection?.Subtitle}
    </p>
    <div className="d-flex justify-content-center align-items-center" style={{ padding: "60px 0", width: "100%" }}>
      <div className="d-flex flex-column align-items-start" style={{ width: "20%", marginRight: 50 }}>
        <div className="d-flex flex-column align-items-start">
          <span style={{
            fontSize: 38,
            fontWeight: "bold",
            color: "#07122E",
            textAlign: "left",
          }}>
            {reviewSection?.Left_Title}
          </span>
          <div style={{
            width: 60,
            paddingBottom: 15,
            borderBottom: "2px solid #FC9C52",
          }}></div>
        </div>
        <p style={{
          maxWidth: 445,
          fontSize: 22,
          fontWeight: 400,
          color: "#6B6C72",
          marginTop: 30,
        }}>
          {reviewSection?.Left_Subtitle}
        </p>
      </div>
      <div style={{ width: "60%" }}>
        <Slider
          dots
          slidesToShow={2}
          slidesToScroll={2}
          nextArrow={<ArrowRight space={-40} />}
          prevArrow={<ArrowLeft space={-40} />}
          responsive={[
            {
              breakpoint: 800,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
          ]}
        >
          {reviews?.map((review) => (
            <Suspense key={review.content._uid} fallback={<div>Loading...</div>}>
              <CarouselItem4
                avatar={review.content.Photo.filename}
                name={review.content.Name}
                job={review.content.Job}
                rating={review.content.Stars}
                description={review.content.Quote}
              />
            </Suspense>
          ))}
        </Slider>
      </div>
    </div>
  </>
));

export default LandingPage;
