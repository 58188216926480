import React, { useState } from "react";
import { Link } from "react-router-dom";

import { history } from '../../../store/history';


const TermsService = (props) => {

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Terms of Service</h1>
      </div>
    </React.Fragment>
  );
}


export default TermsService;
