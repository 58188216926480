import * as types from "./actionTypes";

const initialState = {
  fields: null,
  countries: null,
  languages: null,
  rddEvaluations: null,
  language: null,
  cuisines: null,
  vegetarianTypes: null,
  difficultyLevels: null,
  ingredientInfo: null,
  recipeInfo: null,
  recipe: null,
  contents: null,
  preferences: null,
  votingInfo: null,
  prefer: false,
  weeklyPlans: null,
  groceries: null,
  groceryTypes: null,
  mealLevels: null,
  error: "",
};

const app = (state = initialState, action) => {
  switch (action.type) {
    //COUNTRY REDUCER
    case types.COUNTRY_LOADING:
      return { ...state, countries: null, error: null };
    case types.COUNTRY_SUCCESS:
      return { ...state, countries: action.countries, error: null };
    case types.COUNTRY_ERROR:
      return { ...state, countries: null, error: action.error };

    //CUISINE REDUCER
    case types.CUISINE_LOADING:
      return { ...state, cuisines: null, error: null };
    case types.CUISINE_SUCCESS:
      return { ...state, cuisines: action.cuisines, error: null };
    case types.CUISINE_ERROR:
      return { ...state, cuisines: null, error: action.error };

    //LANGUAGES REDUCER
    case types.LANGUAGES_LOADING:
      return { ...state, languages: null, error: null };
    case types.LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.languages,
        error: null,
      };
    case types.LANGUAGES_ERROR:
      return {
        ...state,
        languages: null,
        error: action.error,
      };

    case types.LANGUAGE_SELECTED:
      return { ...state, language: action.language, error: null };

    case types.RDD_EVAL_LOADING:
      return { ...state, rddEvaluations: null, error: null };
    case types.RDD_EVAL_SUCCESS:
      return {
        ...state,
        rddEvaluations: action.rddEvaluations,
        error: null,
      };
    case types.RDD_EVAL_ERROR:
      return {
        ...state,
        rddEvaluations: null,
        error: action.error,
      };

    //VEGETARIAN REDUCER
    case types.VEGETARIAN_LOADING:
      return { ...state, vegetarianTypes: null, error: null };
    case types.VEGETARIAN_SUCCESS:
      return { ...state, vegetarianTypes: action.vegetarianTypes, error: null };
    case types.VEGETARIAN_ERROR:
      return { ...state, vegetarianTypes: null, error: action.error };

    //INGREDIENT REDUCER
    case types.INGREDIENT_LOADING:
      return { ...state, ingredientInfo: null, error: null };
    case types.INGREDIENT_SUCCESS:
      return { ...state, ingredientInfo: action.ingredientInfo, error: null };
    case types.INGREDIENT_ERROR:
      return { ...state, ingredientInfo: null, error: action.error };

    //CONTENT REDUCER
    case types.CONTENT_LOADING:
      return { ...state, contents: null, error: null };
    case types.CONTENT_SUCCESS:
      return { ...state, contents: action.contents, error: null };
    case types.CONTENT_ERROR:
      return { ...state, contents: null, error: action.error };

    //RECOMMENDATIONS REDUCER
    case types.RECOMMENDATION_SUCCESS:
      return { ...state, recommendations: action.recommendations, error: null };
    case types.RECOMMENDATION_ERROR:
      return { ...state, recommendations: null, error: action.error };

    //RECIPES REDUCER
    case types.RECIPES_LOADING:
      return { ...state, recipeInfo: null, error: null };
    case types.RECIPES_SUCCESS:
      return { ...state, recipeInfo: action.recipeInfo, error: null };
    case types.RECIPES_ERROR:
      return { ...state, recipeInfo: null, error: action.error };

    //RECIPE REDUCER
    case types.RECIPE_LOADING:
      return { ...state, recipe: null, error: null };
    case types.RECIPE_SUCCESS:
      return { ...state, recipe: action.recipe, error: null };
    case types.RECIPE_ERROR:
      return { ...state, recipe: null, error: action.error };

    //PREFERENCE REDUCER
    case types.PREFERENCE_LOADING:
      return { ...state, preferences: null, error: null };
    case types.PREFERENCE_SUCCESS:
      return { ...state, preferences: action.preferences, error: null };
    case types.PREFERENCE_ERROR:
      return { ...state, preferences: null, error: action.error };

    //VOTING REDUCER
    case types.VOTING_LOADING:
      return { ...state, votingInfo: null, error: null };
    case types.VOTING_SUCCESS:
      return { ...state, votingInfo: action.votingInfo, error: null };
    case types.VOTING_ERROR:
      return { ...state, votingInfo: null, error: action.error };

    //PREFER REDUCER
    case types.PREFER_LOADING:
      return { ...state, prefer: null, error: null };
    case types.PREFER_SUCCESS:
      return { ...state, prefer: action.prefer, error: null };
    case types.PREFER_ERROR:
      return { ...state, prefer: null, error: action.error };

    //DIFFICULTY REDUCER
    case types.DIFFICULTY_LOADING:
      return { ...state, difficultyLevels: null, error: null };
    case types.DIFFICULTY_SUCCESS:
      return { ...state, difficultyLevels: action.difficultyList, error: null };
    case types.DIFFICULTY_ERROR:
      return { ...state, difficultyLevels: null, error: action.error };

    //FIELD REDUCER
    case types.FIELD_LOADING:
      return { ...state, fields: null, error: null };
    case types.FIELD_SUCCESS:
      return { ...state, fields: action.fields, error: null };
    case types.FIELD_ERROR:
      return { ...state, fields: null, error: action.error };

    //WEEKLY REDUCER
    case types.WEEKLY_LOADING:
      return { ...state, weeklyPlans: null, error: null };
    case types.WEEKLY_SUCCESS:
      return { ...state, weeklyPlans: action.weeklyPlans, error: null };
    case types.WEEKLY_ERROR:
      return { ...state, weeklyPlans: null, error: action.error };

    //GROCERIES REDUCER
    case types.GROCERIES_LOADING:
      return { ...state, groceries: null, error: null };
    case types.GROCERIES_SUCCESS:
      return { ...state, groceries: action.groceries, error: null };
    case types.GROCERIES_ERROR:
      return { ...state, groceries: null, error: action.error };

    //GROCERY TYPES REDUCER
    case types.GROCERY_TYPES_LOADING:
      return { ...state, groceryTypes: null, error: null };
    case types.GROCERY_TYPES_SUCCESS:
      return { ...state, groceryTypes: action.groceryTypes, error: null };
    case types.GROCERY_TYPES_ERROR:
      return { ...state, groceryTypes: null, error: action.error };

    //MEALLEVELS REDUCER
    case types.MEALLEVELS_LOADING:
      return { ...state, mealLevels: null, error: null };
    case types.MEALLEVELS_SUCCESS:
      return { ...state, mealLevels: action.mealLevels, error: null };
    case types.MEALLEVELS_ERROR:
      return { ...state, mealLevels: null, error: action.error };

    case types.SET_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.payload
      };

    default:
      return state;
  }
};

export default app;
