import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import {
  getGroceryTypes,
  getGroceries,
} from "../../../store/app";
import { history } from "../../../store/history";
import PageTemplate from "../../../components/PageTemplate";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";
import { useStoryblok } from "../../../services/storyblokService";
import MainImage from "../../../assets/image/vegetarian-foods.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Groceries = (props) => {
  const meals = props.location.state?.meals;

  const dispatch = useDispatch();
  const SB_groceries = useStoryblok("groceries");

  const { groceries, groceryTypes } = useSelector(
    (state) => state.app
  );

  const profile = useSelector((state) => state.session.profile);
  const language = profile?.langId ?? 1;

  useEffect(() => {
    dispatch(getGroceries(meals));
  }, [meals, language]);

  useEffect(() => {
    dispatch(getGroceryTypes(language));
  }, [language]);

  if (meals && meals.length === 0) {
    return <div className="HV-center h-100">No Groceries.</div>;
  }

  if (!groceries || !groceryTypes) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color="#FC9C52" size={20} margin={5} />
      </div>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: 30,
          height: 30,
          borderRadius: 50,
          minWidth: "unset",
          backgroundColor: "white",
          position: "absolute",
          left: "80px",
          zIndex: 1,
        }}
        onClick={() => history.goBack()}
      >
        <ArrowBackIosNewIcon
          style={{
            height: 14,
            color: "#B8BABD",
          }}
        />
      </Button>

      <PageTemplate
        backgroundImgSrc={MainImage}
        title={SB_groceries?.content?.Title}
      >
        <div
          className="d-flex flex-column text-center"
          style={{ padding: "6%", gap: "30px" }}
        >
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FAA72D",
                color: "white",
                fontSize: "23px",
                fontWeight: "400",
                borderRadius: "28px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FAA72D",
                },
              }}
            >
              Saved Recipes
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FAA72D",
                color: "white",
                fontSize: "23px",
                fontWeight: "400",
                borderRadius: "28px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FAA72D",
                },
              }}
            >
              Calendar Recipes
            </Button>
          </div> */}
          {groceries?.map((grocery, index) => (
            <Accordion
              defaultExpanded={true}
              key={index}
              style={{
                boxShadow: "-2px 4px 6px 0px #28282833",
                borderRadius: "6px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#B8BABD" }} />}
                style={{
                  fontSize: "24px",
                  color: "#6B6C72",
                  fontWeight: "500",
                  padding: "12px 60px",
                }}
                sx={{
                  "& .MuiAccordionSummary-content ": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    "> p": {
                      marginRight: "10px",
                      fontSize: "15px",
                      color: "#B8BABD",
                    },
                  },
                }}
              >
                {`${
                  groceryTypes
                    ? Object.values(groceryTypes).find(
                        (g) => g.id === grocery.market
                      )?.description ?? "???"
                    : ""
                }`}
                <p>{grocery.groceries.length}</p>
              </AccordionSummary>

              <AccordionDetails
                style={{
                  padding: "12px 60px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                {grocery.groceries.map((groceryItem, itemIndex) => (
                  <div
                    key={itemIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "40px",
                    }}
                  >
                    {groceryItem.image ? (
                      <img
                        src={groceryItem.image}
                        width="90"
                        height="90"
                        style={{ objectFit: "fill" }}
                        loading={"lazy"}
                        alt={groceryItem.description[language]}
                      ></img>
                    ) : (
                      "Not found"
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#6B6C72",
                        }}
                      >
                        {groceryItem.description[language]}
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#B8BABD",
                        }}
                      >
                        {groceryItem.quantity}g
                      </p>
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </PageTemplate>
    </>
  );
};

export default Groceries;
