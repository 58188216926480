import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllData } from "../../../store/app";
import { checkAuthState, getProfile } from "../../../store/session";
import { ApiService } from "../../../services/apiService";
import { createSelector } from "reselect";
import { BeatLoader, GridLoader } from "react-spinners";
import { Redirect, useLocation } from "react-router-dom";
import { useStoryblok } from "services/storyblokService";
import { createDebouncedAction } from "../../../utils/debouncer";
import { showToastr } from "../../../services/themeService";
import getLanguageAndEvaluationsMap from "../../../utils/languageAndEvaluationsMap";
import PageTemplate from "../../../components/PageTemplate";
import TopMenuBar from "components/TopMenuBar";
import Search from "../../../components/Search";
import Filter from "./Filter";
import { Pagination, RecipeItem } from "../../../components";
import MainImage from "../../../assets/image/vegetarian-foods.png";
import Markdown from "react-markdown";
import {
  VEGETARIANFILTER,
  DIFFICULTYFILTER,
  CUISINEFILTER,
  EVALUATIONSTARSFILTER,
} from "./constants";

const VegetarianFoods = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Storyblok content
  const SB_filtersAndOrders = useStoryblok("filters-and-orders");
  const SB_RecipeList = useStoryblok("recipe-list");
  const SB_recipeMultilingueFields = useStoryblok("recipe-multilingue-fields");

  // State management
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [page, setPage] = useState(1);
  const [recipeInfo, setRecipeInfo] = useState();
  const [filterOptions, setFilterOptions] = useState({
    cuisineList: [],
    vegetarianTypeList: [],
    difficultyLevelList: [],
    evaluationStar: { ranges: [] },
    keyword: "",
    sortBy: "",
  });
  const [loading, setLoading] = useState(false);
  const [sortByIndex, setSortByIndex] = useState();

  // Memoized selectors using reselect
  const selectAppState = (state) => state.app;
  const selectVegetarianFoodsData = createSelector(
    [selectAppState],
    (app) => ({
      cuisines: app.cuisines,
      vegetarianTypes: app.vegetarianTypes,
      difficultyLevels: app.difficultyLevels,
      languages: app.languages,
      rddEvaluations: app.rddEvaluations,
      language: app.language,
    })
  );

  const {
    cuisines,
    vegetarianTypes,
    difficultyLevels,
    languages,
    rddEvaluations,
    language,
  } = useSelector(selectVegetarianFoodsData);

  const {
    isAuthenticated,
    user,
    profile,
    loginLoading,
    profileLoading,
  } = useSelector((state) => state.session);

  // Load and save state functions
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("vegetarianFoodsState", serializedState);
    } catch (err) {
      console.error("Could not save state", err);
    }
  };

  const loadState = () => {
    try {
      const serializedState = localStorage.getItem("vegetarianFoodsState");
      if (serializedState === null) {
        return undefined;
      }
      const state = JSON.parse(serializedState);
      if (state.filterOptions) {
        state.filterOptions.keyword = "";
      }
      return state;
    } catch (err) {
      console.error("Could not load state", err);
      return undefined;
    }
  };

  // Effects
  useEffect(() => {
    if (!language || !languages || Object.keys(languages).length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [language, languages]);

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(checkAuthState());
      setIsCheckingAuth(false);
    };
    checkAuth();
    dispatch(fetchAllData());
    dispatch(getProfile());
  }, [dispatch, language]);

  useEffect(() => {
    const savedState = loadState();
    if (savedState) {
      setPage(savedState.page);
      setFilterOptions(savedState.filterOptions);
      setSortByIndex(savedState.sortByIndex);
      fetchData(savedState.filterOptions, savedState.page);
    } else if (profile) {
      if (
        window.history.state &&
        window.history.state.page &&
        window.history.state.filterOptions &&
        window.history.state.meals &&
        window.history.state.sortByIndex
      ) {
        setPage(window.history.state.page);
        setRecipeInfo(window.history.state.meals);
        setFilterOptions({ ...window.history.state.filterOptions });
        setSortByIndex(window.history.state.sortByIndex);
      } else {
        const initialFilterOptions = {
          ...filterOptions,
          cuisineList: profile.cuisines.slice(),
          vegetarianTypeList: profile.vegetarianTypes.slice(),
          evaluationStar: { ranges: [] },
          difficultyLevelList: [],
        };
        fetchData(initialFilterOptions, 1);
        setFilterOptions(initialFilterOptions);

        window.history.replaceState(
          {
            ...window.history.state,
            filterOptions: initialFilterOptions,
            page: 1,
          },
          "",
          location.pathname
        );
      }
    }
  }, [profile]);

  useEffect(() => {
    saveState({
      page,
      filterOptions,
      sortByIndex,
    });
  }, [page, filterOptions, sortByIndex]);

  // Fetch data function
  const fetchData = useCallback( async (filterOptions, page) => {
    setLoading(true);
    try {
      let filter = {};

      if (filterOptions.cuisineList.length > 0)
        filter.cuisineList = filterOptions.cuisineList;
      if (filterOptions.vegetarianTypeList.length > 0)
        filter.vegetarianTypeList = filterOptions.vegetarianTypeList;
      if (filterOptions.difficultyLevelList.length > 0)
        filter.difficultyLevelList = filterOptions.difficultyLevelList;
      if (filterOptions.evaluationStar.ranges.length > 0)
        filter.evaluationStar = {
          ranges: filterOptions.evaluationStar.ranges,
        };

      filter.keyword = filterOptions.keyword;

      const data = await ApiService.getRecipes(
        page,
        18,
        filterOptions.sortBy,
        filter
      );

      setRecipeInfo(data);
      window.history.replaceState(
        { ...window.history.state, meals: data },
        "",
        location.pathname
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (!error.__CANCEL__) {
        showToastr("error", "Request error", "Something went wrong");
      }
      setLoading(false);
    }
  }, []);

  // Debounced fetch data
  const debouncedFetchData = useMemo(() =>
      createDebouncedAction(
        (filterOptions, page) => () => fetchData(filterOptions, page),
        1000 // 1 second debounce
      ),
    [fetchData] // Only recreate if fetchData changes
  );


  // Handlers
  const handlePageChange = (val) => {
    setPage(val);
    saveState({
      page: val,
      filterOptions,
      sortByIndex,
    });
    fetchData(filterOptions, val);
  };

  const handleSearch = (keyword) => {
    const newFilterOptions = { ...filterOptions, keyword };
    setFilterOptions(newFilterOptions);
    saveState({
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex,
    });
    setPage(1);
    dispatch(debouncedFetchData(newFilterOptions, 1));
  };

  const handleFilterCheck = (filterName, newFilters) => {
    let newFilterOptions;
    switch (filterName) {
      case VEGETARIANFILTER:
        newFilterOptions = { ...filterOptions, vegetarianTypeList: newFilters };
        break;
      case DIFFICULTYFILTER:
        newFilterOptions = { ...filterOptions, difficultyLevelList: newFilters };
        break;
      case CUISINEFILTER:
        newFilterOptions = { ...filterOptions, cuisineList: newFilters };
        break;
      case EVALUATIONSTARSFILTER:
        newFilterOptions = {
          ...filterOptions,
          evaluationStar: { ranges: [...newFilters] },
        };
        break;
      default:
        newFilterOptions = filterOptions;
    }

    setFilterOptions(newFilterOptions);
    saveState({
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex,
    });
    setPage(1);
    fetchData(newFilterOptions, 1);
  };

  const handleOrderByClick = (sortBy, newSortByIndex) => {
    if (sortBy === filterOptions.sortBy) {
      sortBy = "";
      newSortByIndex = undefined;
    }
    const newFilterOptions = { ...filterOptions, sortBy };
    setFilterOptions(newFilterOptions);
    setSortByIndex(newSortByIndex);
    saveState({
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex: newSortByIndex,
    });
    setPage(1);
    fetchData(newFilterOptions, 1);
  };

  const resetFilters = () => {
    const initialFilterOptions = {
      cuisineList: profile.cuisines.slice(),
      vegetarianTypeList: profile.vegetarianTypes.slice(),
      evaluationStar: { ranges: [] },
      difficultyLevelList: [],
      keyword: "",
      sortBy: "",
    };
    setFilterOptions(initialFilterOptions);
    setSortByIndex(undefined);
    setPage(1);
    saveState({
      page: 1,
      filterOptions: initialFilterOptions,
      sortByIndex: undefined,
    });
    fetchData(initialFilterOptions, 1);
  };

  // Memoized values
  const rddEvaluationsMap = useMemo(() => {
    if (!languages || !rddEvaluations || !language) return {};
    return getLanguageAndEvaluationsMap(language, languages, rddEvaluations);
  }, [language, languages, rddEvaluations]);

  // Conditional rendering based on authentication and loading state
  if (
    isCheckingAuth ||
    loginLoading ||
    profileLoading ||
    languages === null ||
    rddEvaluations === null ||
    cuisines === null ||
    vegetarianTypes === null ||
    isLoading
  ) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color="#FC9C52" size={20} margin={5} />
      </div>
    );
  }

  if (!isAuthenticated || !user) {
    console.log(
      `User is not authenticated: \nisAuthenticated: ${isAuthenticated}, \nuser: ${user}, \nprofile: ${profile}, \nloginLoading: ${loginLoading}, \nprofileLoading: ${profileLoading}`
    );
    return <Redirect to="/sign-in" />;
  }

  return (
    <>
      <PageTemplate
        backgroundImgSrc={MainImage}
        title={SB_RecipeList?.content?.Title}
        subtitle={<Markdown>{SB_RecipeList?.content?.Body}</Markdown>}
      >
        <div style={{ width: "100%", padding: "20px 30px" }}>
          <TopMenuBar
            selectedSortBy={sortByIndex}
            showFilters={showFilters}
            onClickFiltersBtn={() => setShowFilters(!showFilters)}
            SB_FiltersAndOrders={SB_filtersAndOrders}
            menuItems={[
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_difficulty,
                applyFilter: () => handleOrderByClick("difficultyLevel", 0),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_prep_time,
                applyFilter: () => handleOrderByClick("preparationTime", 1),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_nutrition,
                applyFilter: () => handleOrderByClick("nutrition", 2),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_evaluation,
                applyFilter: () => handleOrderByClick("preferences", 3),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_calorie,
                applyFilter: () => handleOrderByClick("calories", 4),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_protein,
                applyFilter: () => handleOrderByClick("protein", 5),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_sugar,
                applyFilter: () => handleOrderByClick("sugar", 6),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_carbos,
                applyFilter: () => handleOrderByClick("carbohydrate", 7),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_fat,
                applyFilter: () => handleOrderByClick("fat", 8),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_fiber,
                applyFilter: () => handleOrderByClick("fiber", 9),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_date,
                applyFilter: () => handleOrderByClick("date", 10),
              },
            ]}
          />
          <div className="d-flex w-100">
            {/* Filters */}
            <div
              className="col-6 col-md-4 col-lg-3"
              style={{
                display: `${showFilters ? "block" : "none"}`,
                paddingLeft: 0,
              }}
            >
              <Search
                value={filterOptions.keyword}
                placeholder={SB_filtersAndOrders?.content?.Search_Recipe}
                onChange={handleSearch}
                style={{ width: "100%" }}
              />
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Cuisine}
                filterValues={cuisines}
                filterOptionsChecked={filterOptions.cuisineList}
                filterName={CUISINEFILTER}
                applyFilters={handleFilterCheck}
                sortFunc={(pair1, pair2) =>
                  pair1[1].name.localeCompare(pair2[1].name)
                }
              />
              <Filter
                SB_filterName={
                  SB_recipeMultilingueFields?.content?.VegeterianType
                }
                filterValues={vegetarianTypes}
                filterOptionsChecked={filterOptions.vegetarianTypeList}
                filterName={VEGETARIANFILTER}
                applyFilters={handleFilterCheck}
              />
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Difficulty}
                filterValues={difficultyLevels}
                filterOptionsChecked={filterOptions.difficultyLevelList}
                filterName={DIFFICULTYFILTER}
                applyFilters={handleFilterCheck}
              />
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Nutrition}
                filterValues={rddEvaluationsMap}
                filterOptionsChecked={filterOptions.evaluationStar.ranges}
                filterName={EVALUATIONSTARSFILTER}
                applyFilters={handleFilterCheck}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={resetFilters}
                  style={{
                    fontSize: "0.9rem",
                    padding: "0.375rem 0.75rem",
                    minWidth: "120px",
                  }}
                  className="btn btn-secondary"
                >
                  Reset Filters
                </button>
              </div>
            </div>
            {/* Recipe List */}
            <div
              style={{ paddingRight: 0 }}
              className={`${
                showFilters ? "col-6" : "col-12"
              } ${showFilters ? "col-md-8" : "col-md-12"} ${
                showFilters ? "col-lg-9" : "col-lg-12"
              } m-0`}
            >
              {!loading && recipeInfo ? (
                <>
                  <div className="row m-0 w-100">
                    {recipeInfo.meals?.length > 0 ? (
                      recipeInfo.meals.map((recipe, index) => (
                        <div
                          key={index}
                          className={`${
                            showFilters ? "col-lg-4" : "col-lg-3"
                          } col-md-6`}
                        >
                          <RecipeItem
                            recipe={recipe}
                            SB_recipeMultilingueFields={
                              SB_recipeMultilingueFields
                            }
                            showFavIcon
                            clickVoting
                          />
                        </div>
                      ))
                    ) : (
                      <div className="">
                        <span>No results found</span>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="">
                  <GridLoader
                    color="#FC9C52"
                    loading={true}
                    cssOverride={{ display: "block", margin: "auto" }}
                    size={15}
                    margin={2}
                  />
                </div>
              )}
            </div>
          </div>
          {recipeInfo?.pages > 1 && (
            <div className="d-flex justify-content-center my-5">
              <Pagination
                numPages={recipeInfo?.pages}
                page={page}
                onChangePage={(e, page) => handlePageChange(page)}
              />
            </div>
          )}
        </div>
      </PageTemplate>
    </>
  );
};

export default VegetarianFoods;
