import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Button } from "@mui/material";

import { Dialog } from "../../components";
import { getWeeklyPlan, updateWeeklyPlanList, setCalendarDate } from "../../store/app";
import { FuncService } from "../../services/funcService";
import { useStoryblok } from "../../services/storyblokService";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { history } from "../../store/history";
import { addDays, getSunday, differenceInDays} from "../../utils/dateUtils";

const Calendar = (props) => {
  const dispatch = useDispatch();
  const SB_weeklyPlan = useStoryblok("weekly-plan");

  const weeklyPlans = useSelector((state) => state.app.weeklyPlans);
  const profile = useSelector((state) => state.session.profile);
  const calendarDate = useSelector((state) => state.app.calendarDate);


  const language = profile?.langId ?? 1;

  const { onChangeMealTime } = props;
  const [currentMeal, setCurrentMeal] = useState({});
  const [currentEdit, setCurrentEdit] = useState({});
  const [updatedWeeklyPlans, setUpdatedWeeklyPlans] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!calendarDate) {
      // If no date is set, initialize it to the Sunday of the current week
      const initialDate = getSunday(new Date());
      dispatch(setCalendarDate(initialDate));
      dispatch(getWeeklyPlan(FuncService.formatDate(initialDate, true)));
    } else {
      // If a date is already set, just fetch the weekly plan for that date
      dispatch(getWeeklyPlan(FuncService.formatDate(calendarDate, true)));
    }
  }, [calendarDate, dispatch]);

  useEffect(() => {
    if (!weeklyPlans) return;

    // setUpdatedWeeklyPlans(weeklyPlans.slice());
    setUpdatedWeeklyPlans(JSON.parse(JSON.stringify(weeklyPlans)));
  }, [weeklyPlans, dispatch]);

  const handlePrev = () => {
    dispatch(setCalendarDate(addDays(calendarDate, -7)));
  };

  const handleToday = () => {
    dispatch(setCalendarDate(getSunday(new Date())));
  };

  const handleNext = () => {
    dispatch(setCalendarDate(addDays(calendarDate, 7)));
  };

  const handleEdit = (date, mealTime) => {
    setCurrentEdit({
      date: date,
      mealTime: mealTime,
    });

    setOpen(true);
  };

  const handleClick = (dateIndex, timeIndex) => {
    console.log(dateIndex, timeIndex);
    setCurrentMeal({
      date: addDays(calendarDate, dateIndex),
      time: timeIndex,
    });

    if (onChangeMealTime)
      onChangeMealTime(
        FuncService.formatDate(addDays(calendarDate, dateIndex), true),
        timeIndex + 1
      );
  };

  const handleSave = (mealID) => {
    handleDelete(mealID);
    dispatch(
      updateWeeklyPlanList(
        currentEdit.date,
        currentEdit.mealTime,
        updatedWeeklyPlans.find(
          (weeklyPlan) =>
            weeklyPlan.date === currentEdit.date &&
            weeklyPlan.mealTime === currentEdit.mealTime
        ).meals
      )
    );

    setOpen(false);
  };

  const handleClose = () => {
    setUpdatedWeeklyPlans(JSON.parse(JSON.stringify(weeklyPlans)));

    setOpen(false);
  };

  const handleDelete = (id) => {
    let tmpWeeklyPlans = updatedWeeklyPlans.slice();
    let tmpWeeklyPlan = tmpWeeklyPlans.find(
      (weeklyPlan) =>
        weeklyPlan.date === currentEdit.date &&
        weeklyPlan.mealTime === currentEdit.mealTime
    );
    const index = tmpWeeklyPlan.meals.indexOf(
      tmpWeeklyPlan.meals.find((meal) => meal.id === id)
    );
    tmpWeeklyPlan.meals.splice(index, 1);

    setUpdatedWeeklyPlans(tmpWeeklyPlans);
  };

  const getDayName = (num) => {
     const dayNames = [
        SB_weeklyPlan?.content?.Sun,
        SB_weeklyPlan?.content?.Mon,
        SB_weeklyPlan?.content?.Tue,
        SB_weeklyPlan?.content?.Wed,
        SB_weeklyPlan?.content?.Thu,
        SB_weeklyPlan?.content?.Fri,
        SB_weeklyPlan?.content?.Sat
    ];
    return dayNames[num];

  };

  const getMonthName = (num) => {
    const monthNames = [
        SB_weeklyPlan?.content?.Jan,
        SB_weeklyPlan?.content?.Feb,
        SB_weeklyPlan?.content?.Mar,
        SB_weeklyPlan?.content?.Apr,
        SB_weeklyPlan?.content?.May,
        SB_weeklyPlan?.content?.Jun,
        SB_weeklyPlan?.content?.Jul,
        SB_weeklyPlan?.content?.Aug,
        SB_weeklyPlan?.content?.Sep,
        SB_weeklyPlan?.content?.Oct,
        SB_weeklyPlan?.content?.Nov,
        SB_weeklyPlan?.content?.Dec
    ];
    return monthNames[num];
  };

  if (!weeklyPlans) {
    return (
      <div className="HV-center h-100">
        <ClipLoader color="#FC9C52" size={35} />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: 30,
            height: 30,
            borderRadius: 50,
            minWidth: "unset",
            backgroundColor: "white",
            zIndex: 1,
            "&:hover": {
              backgroundColor: "#FAA72D",
              svg: {
                color: "white",
              },
            },
          }}
          onClick={() => handlePrev()}
        >
          <ArrowBackIosNewIcon
            sx={{
              height: 14,
              color: "#B8BABD",
            }}
          />
        </Button>
        <p style={{ fontSize: "32px", fontWeight: "700", color: "#FAA72D" }}>
          {getMonthName(calendarDate.getMonth())} {calendarDate.getFullYear()}
        </p>
        <Button
          variant="contained"
          sx={{
            width: 30,
            height: 30,
            borderRadius: 50,
            minWidth: "unset",
            backgroundColor: "white",
            zIndex: 1,
            "&:hover": {
              backgroundColor: "#FAA72D",
              svg: {
                color: "white",
              },
            },
          }}
          onClick={() => handleNext()}
        >
          <ArrowBackIosNewIcon
            sx={{
              rotate: "180deg",
              height: 14,
              color: "#B8BABD",
            }}
          />
        </Button>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
          gridTemplateRows: "repeat(3, 1fr)",
          gridColumnGap: "12px",
          gridRowGap: "30px",
          alignItems: "center",
          justifyItems: "center",
          padding: "20px",
          overflow: "auto",
        }}
      >
        {/* row1 */}
        <div
          style={{
            gridArea: `2 / 1 / span 1 / span 1`,
            fontSize: "24px",
            fontWeight: "700",
          }}
        >
          {SB_weeklyPlan?.content?.Lunch}
        </div>
        <div
          style={{
            gridArea: `3 / 1 / span 1 / span 1`,
            fontSize: "24px",
            fontWeight: "700",
          }}
        >
          {SB_weeklyPlan?.content?.Dinner}
        </div>
        {Array.from(Array(7).keys()).map((date, dateIndex) => (
          <div
            style={{
              gridArea: `1 / ${dateIndex + 2} / span 1 / span 1`,
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "700",
                color: "#6B6C72",
              }}
            >
              <span>{addDays(calendarDate, dateIndex).getDate()}</span>
              <br></br>
              <span>{getDayName(addDays(calendarDate, dateIndex).getDay())}</span>
            </div>
          </div>
        ))}
        {Array.from(Array(7).keys()).map((date, dateIndex) => {
          return Array.from(Array(2).keys()).map((mealTime, timeIndex) => {
            const plannedMeals = weeklyPlans.find(
              (weeklyPlan) =>
                weeklyPlan.date ===
                  FuncService.formatDate(addDays(calendarDate, dateIndex), true) &&
                weeklyPlan.mealTime === timeIndex + 1
            );
            return (
              <div
                key={timeIndex}
                className="pointer"
                style={{
                  gridArea: `${timeIndex + 2} / ${
                    dateIndex + 2
                  } / span 1 / span 1`,
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "28px",
                  color: "white",
                  backgroundColor: "white",
                  boxShadow: "0px 1px 15px 0px #00000026",
                  minHeight: "100px",
                  minWidth: "100px",
                  ...(differenceInDays(
                    currentMeal.date,
                    addDays(calendarDate, dateIndex)
                  ) === 0 && currentMeal.time === timeIndex
                    ? { border: "5px solid #FAA72D" }
                    : null),
                }}
                onClick={() => {
                  if (onChangeMealTime) {
                    handleClick(dateIndex, timeIndex);
                  }
                }}
              >
                {plannedMeals?.meals?.[0]?.image && (
                  <>
                    <div
                      onClick={() => {
                        if (!onChangeMealTime && plannedMeals?.meals?.[0]?.id) {
                          history.push(
                            `/vegetarian-food/detail/${plannedMeals?.meals?.[0]?.id}`
                          );
                        }
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          position: "absolute",
                          inset: 0,
                          borderRadius: "23px",
                        }}
                        src={`${plannedMeals.meals[0].image}`}
                        alt={plannedMeals.meals[0].title[language]}
                        loading={"lazy"}
                      ></img>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(33, 16, 0, 0.4)",
                          zIndex: 1,
                          borderRadius: "23px",
                          position: "absolute",
                          inset: 0,
                        }}
                      ></div>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          zIndex: 1,
                          borderRadius: "23px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 15,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          {plannedMeals?.meals[0].title[language]}
                        </p>

                        <p
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {Math.round(plannedMeals?.meals[0].calories)} KCal
                        </p>
                      </div>
                    </div>
                    {!onChangeMealTime && (
                      <div
                        style={{
                          position: "absolute",
                          height: "30px",
                          width: "30px",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          top: -10,
                          right: -10,
                          zIndex: 1,
                          boxShadow: "0px 0px 10px 0px #00000026",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FEBA47",
                          fontSize: "34px",
                        }}
                        onClick={() =>
                          handleEdit(
                            FuncService.formatDate(
                              addDays(calendarDate, dateIndex),
                              true
                            ),
                            timeIndex + 1
                          )
                        }
                      >
                        <p>-</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          });
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "60px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleToday()}
          sx={{
            backgroundColor: "#FAA72D",

            "&:hover": {
              backgroundColor: "#FAA72D",
            },
          }}
        >
          {SB_weeklyPlan?.content?.JumpToday}
        </Button>
      </div>

      <Dialog open={open} fullWidth={false} onClose={() => setOpen(false)}>
        <div
          style={{
            margin: "30px 0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {updatedWeeklyPlans
            .filter(
              (weeklyPlan) =>
                weeklyPlan.date === currentEdit.date &&
                weeklyPlan.mealTime === currentEdit.mealTime
            )
            .map((weeklyPlan) =>
              weeklyPlan.meals.map((meal, mealIndex) => (
                <>
                  <div
                    key={mealIndex}
                    className="d-flex justify-content-between align-items-center mt-2"
                    style={{
                      fontSize: "30px",
                      fontWeight: "700",
                      color: "#07122E",
                      textAlign: "center",
                    }}
                  >
                    <p>
                      {SB_weeklyPlan?.content?.Remove_Recipe_Quest}{" "}
                      <span style={{ color: "#FAA72D" }}>
                        {meal.title[language]}
                      </span>{" "}
                      ?
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        color: "#FAA72D",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderRadius: "28px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                      onClick={() => handleSave(meal.id)}
                    >
                      {SB_weeklyPlan?.content?.Answer_Y}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FAA72D",
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderRadius: "28px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#FAA72D",
                        },
                      }}
                      onClick={handleClose}
                    >
                      {SB_weeklyPlan?.content?.Answer_N}
                    </Button>
                  </div>
                </>
              ))
            )}
        </div>
      </Dialog>
    </>
  );
};

export default Calendar;
