import { CheckBox } from "../../../components";
import { EVALUATIONSTARSFILTER } from "./constants";
import React from "react";

const Filter = ({
  SB_filterName,
  filterValues,
  filterOptionsChecked,
  filterName,
  applyFilters,
  sortFunc
}) => {
  const handleOnClick = (id) => {
    let tmpTypes = filterOptionsChecked;
    if (tmpTypes.find((tmpFilterType) => tmpFilterType === id) === undefined) {
      tmpTypes.push(id);
    } else {
      const index = tmpTypes.indexOf(id);
      tmpTypes.splice(index, 1);
    }
    applyFilters(filterName, tmpTypes);
  };

  const sort = (filterValues) => {
    if (sortFunc === undefined) {
      return filterValues;
    }
    return filterValues.sort(sortFunc);
  }

  return (
    <>
      <p style={{ color: "#07122E", fontWeight: "bold", marginTop: 40 }}>
        {SB_filterName}
      </p>
      <div
        style={{
          color: "#6B6C72",
          fontSize: 16,
          maxHeight: 150,
          overflowY: "auto",
          padding: "0 5%",
        }}
      >
        {filterValues &&
          sort(Object.entries(filterValues)).map((filter, index) => {
            return (
              <CheckBox
                key={`${filterName}_${index}`}
                id={`${filterName}_${index}`}
                style={{
                  fontSize: 16,
                  color: "#6B6C72",
                  margin: "10px 0",
                }}
                checked={filterOptionsChecked.includes(
                  filterName === EVALUATIONSTARSFILTER
                    ? filter[1].value
                    : parseInt(filter[0])
                )}
                onClick={() =>
                  filterName === EVALUATIONSTARSFILTER
                    ? handleOnClick(filter[1].value)
                    : handleOnClick(parseInt(filter[0]))
                }
              >
                {filter[1].name}
              </CheckBox>
            );
          })}
      </div>
    </>
  );
};

export default Filter;
