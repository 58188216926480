import { Button, Pagination, RecipeItem } from "../../../components";
import {
  CUISINEFILTER,
  DIFFICULTYFILTER,
  EVALUATIONSTARSFILTER,
  VEGETARIANFILTER,
} from "../VegetarianFoods/constants";
import {Redirect, useLocation} from "react-router-dom";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  clearPrefer,
  getCuisineList,
  getDifficultyLevelList,
  getFieldList,
  getGroceryTypes,
  getMealLevels,
  getRDDEvaluations,
  getVegetarianTypeList,
} from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";

import { ApiService } from "../../../services/apiService";
import { FiShoppingCart } from "react-icons/fi";
import Filter from "../VegetarianFoods/Filter";
import {BeatLoader, GridLoader} from "react-spinners";
import MainImage from "../../../assets/image/vegetarian-foods.png";
import Markdown from "react-markdown";
import PageTemplate from "../../../components/PageTemplate";
import Search from "../../../components/Search";
import TopMenuBar from "../../../components/TopMenuBar";
import { history } from "../../../store/history";
import { showToastr } from "../../../services/themeService";
import { useStoryblok } from "../../../services/storyblokService";
import { createDebouncedAction } from "../../../utils/debouncer";
import {checkAuthState} from "../../../store/session";
import getLanguageAndEvaluationsMap from "../../../utils/languageAndEvaluationsMap";

const List = () => {
  const dispatch = useDispatch();
  const location = useLocation();


  const SB_languages = useStoryblok("languages");
  const SB_myList = useStoryblok("my-list");
  const SB_filtersAndOrders = useStoryblok("filters-and-orders");
  const SB_recipeMultilingueFields = useStoryblok("recipe-multilingue-fields");

  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('preferredRecipesState', serializedState);
    } catch (err) {
      console.error('Could not save state', err);
    }
  };

  const loadState = () => {
    try {
      const serializedState = localStorage.getItem('preferredRecipesState');
      if (serializedState === null) {
        return undefined;
      }
      const state = JSON.parse(serializedState);
      // Clear the keyword (text filter) while preserving other filter options
      if (state.filterOptions) {
        state.filterOptions.keyword = "";
      }
      return state;
    } catch (err) {
      console.error('Could not load state', err);
      return undefined;
    }
  };

  const [showFilters, setShowFilters] = useState(true);

  const { cuisines, vegetarianTypes, difficultyLevels, language, languages, rddEvaluations } = useSelector(
    (state) => state.app
  );
  const { isAuthenticated, user, profile, loginLoading, profileLoading } = useSelector(state => state.session);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);


  const [page, setPage] = useState(1);
  const [meals, setMeals] = useState(null);

  const [filterOptions, setFilterOptions] = useState({
    cuisineList: [],
    vegetarianTypeList: [],
    difficultyLevelList: [],
    evaluationStar: { ranges: [] },
    keyword: "",
    sortBy: "",
  });

  const [loading, setLoading] = useState(false);
  const [sortByIndex, setSortByIndex] = useState();
  const [, setAvailableLangs] = useState([]);
  const [, setSelectedLang] = useState();
  const count = 18;
  useEffect(() => {
    if (SB_languages?.content?.Language.length > 0) {
      let aux = [];
      SB_languages?.content?.Language.forEach((element) => {
        if (element.Enabled)
          aux.push({ ...element, value: element.Id, label: element.Name });
      });
      setAvailableLangs(aux);
      setSelectedLang(aux.find((l) => l.Id === language));
    }
  }, [SB_languages, language]);


  useEffect(() => {
    const savedState = loadState();
    if (savedState) {
      console.log("Setting from saved state:", savedState.meals)
      setPage(savedState.page);
      setFilterOptions(savedState.filterOptions);
      setSortByIndex(savedState.sortByIndex);
      fetchData(savedState.filterOptions, savedState.page);
    }else if (profile) {
      if (
        window &&
        window.history &&
        window.history.state &&
        window.history.state.page &&
        window.history.state.filterOptions &&
        window.history.state.meals &&
        window.history.state.sortByIndex
      ) {
        setPage(window.history.state.page);
        setMeals(window.history.state.meals);
        setFilterOptions({ ...window.history.state.filterOptions });
        setSortByIndex(window.history.state.sortByIndex);
      } else {
        fetchData(
          {
            ...filterOptions,
            cuisineList: profile.cuisines.slice(),
            vegetarianTypeList: profile.vegetarianTypes.slice(),
            evaluationStar: { ranges: [] },
            difficultyLevelList: [],
          },
          1
        );
        setFilterOptions({
          ...filterOptions,
          cuisineList: profile.cuisines.slice(),
          vegetarianTypeList: profile.vegetarianTypes.slice(),
          evaluationStar: { ranges: [] },
          difficultyLevelList: [],
        });

        window.history.replaceState(
          {
            ...window.history.state,
            filterOptions: {
              ...filterOptions,
              cuisineList: profile.cuisines.slice(),
              vegetarianTypeList: profile.vegetarianTypes.slice(),
              evaluationStar: { ranges: [] },
              difficultyLevelList: [],
            },
            page: 1,
          },
          "",
          location.pathname
        );
      }
    }
  }, [profile]);

  useEffect(() => {
    saveState({
      page,
      filterOptions,
      sortByIndex
    });
  }, [page, filterOptions, sortByIndex]);

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(checkAuthState());
      setIsCheckingAuth(false);
    };
    checkAuth();
    dispatch(getMealLevels());
    dispatch(getGroceryTypes());
    dispatch(getDifficultyLevelList());
    dispatch(getFieldList());
    dispatch(getCuisineList());
    dispatch(getVegetarianTypeList());
    dispatch(getRDDEvaluations());
  }, [dispatch, language]);

  const fetchData = useCallback(async (filterOptions, page) => {
    setLoading(true);

    try {
      let filter = {};

      if (filterOptions.cuisineList.length > 0)
        filter.cuisineList = filterOptions.cuisineList;
      if (filterOptions.vegetarianTypeList.length > 0)
        filter.vegetarianTypeList = filterOptions.vegetarianTypeList;
      if (filterOptions.difficultyLevelList.length > 0)
        filter.difficultyLevelList = filterOptions.difficultyLevelList;
      if (filterOptions.evaluationStar.ranges.length > 0)
        filter.evaluationStar = {
          ranges: filterOptions.evaluationStar.ranges,
        };

      filter.keyword = filterOptions.keyword;

      const data = await ApiService.getPrefers(
        page,
        count,
        filterOptions.sortBy,
        filter
      );

      setMeals(data);
      window.history.replaceState(
        { ...window.history.state, meals: data },
        "",
        location.pathname
      );
      setLoading(false);
    } catch (error) {
      if (error.__CANCEL__) {
        // Request was aborted, ignore
        console.log("Request aborted:", error.message);
      } else {
        console.error("Error fetching data:", error);
        showToastr("error", "Request error", "Something went wrong");
        setLoading(false);
      }
    }
  }, []);

  const debouncedFetchData = useMemo(() =>
      createDebouncedAction(
        (filterOptions, page) => () => fetchData(filterOptions, page),
        1000 // 1 second debounce
      ),
    [fetchData] // Only recreate if fetchData changes
  );

  const handlePageChange = (val) => {
    setPage(val);
    saveState({
      page: val,
      filterOptions,
      sortByIndex
    })
    fetchData(filterOptions, val);
  };

  const handleClear = () => {
    ApiService.deleteMyList()
      .then((data) => {
        console.log("data:", data);
        showToastr("info", "Success", "Successfully deleted list");
        setMeals({});
        dispatch(clearPrefer());
      })
      .catch((e) => {
        console.log("Error deleting list:", e);
        showToastr(
          "error",
          "Error",
          "An error occurred while deleting the list"
        );
      });
  };

  const handleGroceries = () => {
    history.push("/groceries", {
      meals: meals.meals,
      from: "/preferences/list",
    });
  };

  const handleOrderByClick = (sortBy, newSortByIndex) => {
    if (sortBy === filterOptions.sortBy) {
      sortBy = "";
      newSortByIndex = undefined;
    }
    const newFilterOptions = { ...filterOptions, sortBy };
    setFilterOptions(newFilterOptions);
    setSortByIndex(newSortByIndex);
    const newState = {
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex: newSortByIndex
    };
    saveState(newState);
    setPage(1);
    fetchData(newFilterOptions, 1);
  };

  const handleSearch = (keyword) => {
    const newFilterOptions = { ...filterOptions, keyword: keyword };
    setFilterOptions(newFilterOptions);
    const newState = {
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex
    };

    saveState(newState);
    setPage(1);
    dispatch(debouncedFetchData(newFilterOptions, 1));
  };

  const handleFilterCheck = (filterName, newFilters) => {
    let newFilterOptions;
    switch (filterName) {
      case VEGETARIANFILTER:
        newFilterOptions = { ...filterOptions, vegetarianTypeList: newFilters };
        break;
      case DIFFICULTYFILTER:
        newFilterOptions = { ...filterOptions, difficultyLevelList: newFilters };
        break;
      case CUISINEFILTER:
        newFilterOptions = { ...filterOptions, cuisineList: newFilters };
        break;
      case EVALUATIONSTARSFILTER:
        newFilterOptions = {
          ...filterOptions,
          evaluationStar: { ranges: [...newFilters] }
        };
        break;
      default:
        newFilterOptions = filterOptions;
    }

    setFilterOptions(newFilterOptions);
    const newState = {
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex
    };
    saveState(newState);
    setPage(1);
    fetchData(newFilterOptions, 1);
  };

  const resetFilters = () => {
    const initialFilterOptions = {
      cuisineList: profile.cuisines.slice(),
      vegetarianTypeList: profile.vegetarianTypes.slice(),
      evaluationStar: { ranges: [] },
      difficultyLevelList: [],
      keyword: "",
      sortBy: "",
    };
    setFilterOptions(initialFilterOptions);
    setSortByIndex(undefined);
    setPage(1);
    saveState({
      page: 1,
      filterOptions: initialFilterOptions,
      sortByIndex: undefined
    });
    fetchData(initialFilterOptions, 1);
  };

  if (isCheckingAuth || loginLoading || profileLoading || languages === null || rddEvaluations === null) {
    return (<div className="HV-center h-100">
      <BeatLoader color="#FC9C52" size={20} margin={5}/>
    </div>);
  }

  if (!isAuthenticated || !user) {
    console.log(`User is not authenticated: \nisAuthenticated: ${isAuthenticated}, \nuser: ${user}, \nprofile: ${profile}, \nloginLoading${loginLoading}, \nprofileLoading: ${profileLoading}`);
    return <Redirect to="/sign-in"/>;
  }

  const  rddEvaluationsMap = getLanguageAndEvaluationsMap(language, languages, rddEvaluations);


  return (
    <>
      <PageTemplate
        backgroundImgSrc={MainImage}
        title={SB_myList?.content?.Title}
        subtitle={<Markdown>{SB_myList?.content?.Body}</Markdown>}
      >
        <div
          style={{
            width: "100%",
            padding: "20px 30px",
          }}
        >
          <div
            style={{
              position: "absolute",
              marginTop: "48px",
              display: "flex",
              justifyContent: "center",
              left: "50%",
              transform: "translate(-50% ,0)",
            }}
          >
            {meals?.meals?.length > 0 ? (
              <>
                <Button
                  variant="contained"
                  bgColor="primary"
                  fontColor="white"
                  className="mr-2"
                  height={38}
                  onClick={handleClear}
                >
                  {SB_myList?.content?.Clear_list}
                </Button>
                <Button
                  variant="contained"
                  bgColor="secondary"
                  fontColor="white"
                  height={38}
                  onClick={handleGroceries}
                >
                  <FiShoppingCart
                    style={{ fontSize: 16, color: "white", marginRight: 10 }}
                  />
                  {SB_myList?.content?.Groceries}
                </Button>
              </>
            ) : null}
          </div>
          <TopMenuBar
            selectedSortBy={sortByIndex}
            showFilters={showFilters}
            onClickFiltersBtn={() => setShowFilters(!showFilters)}
            SB_FiltersAndOrders={SB_filtersAndOrders}
            menuItems={[
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_difficulty,
                applyFilter: () => handleOrderByClick("difficultyLevel", 0),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_prep_time,
                applyFilter: () => handleOrderByClick("preparationTime", 1),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_nutrition,
                applyFilter: () => handleOrderByClick("nutrition", 2),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_evaluation,
                applyFilter: () => handleOrderByClick("preferences", 3),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_calorie,
                applyFilter: () => handleOrderByClick("calories", 4),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_protein,
                applyFilter: () => handleOrderByClick("protein", 5),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_sugar,
                applyFilter: () => handleOrderByClick("sugar", 6),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_carbos,
                applyFilter: () => handleOrderByClick("carbohydrate", 7),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_fat,
                applyFilter: () => handleOrderByClick("fat", 8),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_fiber,
                applyFilter: () => handleOrderByClick("fiber", 9),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_date,
                applyFilter: () => handleOrderByClick("date", 10),
              },
            ]}
          ></TopMenuBar>
          <div className="d-flex w-100">
            <div
              className="col-6 col-md-4 col-lg-3"
              style={{display: `${showFilters ? "block" : "none"}`}}
            >
              <Search
                value={filterOptions.keyword}
                placeholder={SB_filtersAndOrders?.content?.Search_Recipe}
                onChange={handleSearch}
                style={{width: "100%"}}
              ></Search>
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Cuisine}
                filterValues={cuisines}
                filterOptionsChecked={filterOptions.cuisineList}
                filterName={CUISINEFILTER}
                applyFilters={handleFilterCheck}
                sortFunc={(pair1, pair2) => pair1[1].name.localeCompare(pair2[1].name)}
              ></Filter>
              <Filter
                SB_filterName={
                  SB_recipeMultilingueFields?.content?.VegeterianType
                }
                filterValues={vegetarianTypes}
                filterOptionsChecked={filterOptions.vegetarianTypeList}
                filterName={VEGETARIANFILTER}
                applyFilters={handleFilterCheck}
              ></Filter>
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Difficulty}
                filterValues={difficultyLevels}
                filterOptionsChecked={filterOptions.difficultyLevelList}
                filterName={DIFFICULTYFILTER}
                applyFilters={handleFilterCheck}
              ></Filter>
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Nutrition}
                filterValues={rddEvaluationsMap}
                filterOptionsChecked={filterOptions.evaluationStar.ranges}
                filterName={EVALUATIONSTARSFILTER}
                applyFilters={handleFilterCheck}
              ></Filter>

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
              }}>
                <button
                  onClick={resetFilters}
                  style={{
                    fontSize: '0.9rem',
                    padding: '0.375rem 0.75rem',
                    minWidth: '120px'
                  }}
                  className="btn btn-secondary"
                >
                  Reset Filters
                </button>
              </div>

            </div>

            <div
              style={{paddingRight: 0}}
              className={`${showFilters ? "col-6" : "col-12"} ${
                showFilters ? "col-md-8" : "col-md-12"
              } ${showFilters ? "col-lg-9" : "col-lg-12"}
      } m-0`}
            >
              {!loading && meals ? (
                <>
                  <div className="row m-0 w-100">
                    {meals.meals?.map((recipe, index) => (
                      <div
                        key={index}
                        className={`${
                          showFilters ? "col-lg-4" : "col-lg-3"
                        } col-md-6`}
                      >
                        <RecipeItem
                          recipe={recipe}
                          SB_recipeMultilingueFields={
                            SB_recipeMultilingueFields
                          }
                          showFavIcon
                          clickVoting
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="">
                  <GridLoader
                    color="#FC9C52"
                    loading={true}
                    cssOverride={{ display: "block", margin: "auto" }}
                    size={15}
                    margin={2}
                  />
                </div>
              )}
            </div>
          </div>
          {meals?.pages > 1 && (
            <div className="d-flex justify-content-center my-5">
              <Pagination
                numPages={meals.pages}
                page={page}
                onChangePage={(e, page) => handlePageChange(page)}
              />
            </div>
          )}
        </div>
      </PageTemplate>
    </>
  );
};

export default List;
