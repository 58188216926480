import React from "react";

const { FiChevronRight } = require("react-icons/fi");

const ArrowRight = (props) => {
  const { className, style, onClick, space } = props;

  return (
    <div
      className={className + " orangeBtn"}
      style={{
        ...style,
        display: "block",
        width: 40,
        height: 40,
        right: space,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      {<FiChevronRight style={{ fontSize: 20 }} />}
    </div>
  );
};

export default ArrowRight;
