export function addDays(date, days) {
    if (!(date instanceof Date) || typeof days !== 'number') {
        throw new Error('Invalid arguments: date must be a Date object and days must be a number');
    }
    const result = new Date(date.valueOf());
    result.setDate(result.getDate() + days);
    return result;
}

export function getSunday(date) {
    date = new Date(date);
    var day = date.getDay(),
      diff = date.getDate() - day; // adjust when day is sunday

    return new Date(date.setDate(diff));
}

export function differenceInDays(d0, d1) {
    // Copy dates so don't affect originals
    d0 = new Date(+d0);
    d1 = new Date(+d1);

    // Set to noon
    d0.setHours(12, 0, 0, 0);
    d1.setHours(12, 0, 0, 0);

    // Get difference in whole days, divide by milliseconds in one day
    // and round to remove any daylight saving boundary effects
    return Math.round((d1 - d0) / 8.64e7);
}