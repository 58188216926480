import React from "react";
import { useSelector } from "react-redux";
import styles from "./IngredientElementItem.module.css";

const IngredientElementItem = (props) => {
  const { ingredient } = props;

  const profile = useSelector((state) => state.session.profile);

  const language = profile?.langId ?? 1;

  return (
    <div className={styles.ingredientElementItem_card}>
      <div className={styles.ingredientElementItem_imgContainer}>
        <img src={ingredient.image} alt="" />
      </div>
      <div className={styles.ingredientElementItem_title}>
        <p
          style={{
            fontSize: 16,
            fontWeight: 900,
            color: "black",
          }}
        >
          {ingredient.friendlyDescription?.[language]}
        </p>
      </div>
      <div className={styles.ingredientElementItem_ingredients}>
        <p style={{ color: "#07122E", marginBottom: 5 }}>
          {ingredient.descriptionBase?.[language]}
        </p>
        <p className="m-0">{`${ingredient.quantity} g`}</p>
      </div>
    </div>
  );
};

export default IngredientElementItem;
