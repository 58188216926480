export default {
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFF1E6',
    color: '#07122E',
    minWidth: 20,
  },
  root: {
    minWidth: 20,
  }
};
